<template>
    <div class="forbidden_page">
        <img class="image" src="../../assets/error/error-occurred.svg" alt="Error occured" />
        <div class="text">
            <p class="title">
                {{ $t("cp__error_page__server_error__title") }}
            </p>
            <p class="subtitle" v-html="$t('cp__error_page__server_error__subtitle')" />
        </div>
        <div class="footer">
            <p class="footer_text">{{ error }}</p>
            <p class="footer_text">{{ date }}</p>
        </div>
    </div>
</template>

<script>
import { format } from "date-fns";

export default {
    name: "ServerError",
    props: {
        error: {
            type: String,
        },
    },
    computed: {
        date() {
            return format(new Date(), "dd-MM-yyyy HH:mm");
        },
    },
    created() {
        if (!this.error) {
            this.$router.push("/");
        }
    },
};
</script>

<style lang="scss" scoped>
@import "../../style_variables/style_variables.scss";
@import "~include-media";

.forbidden_page {
    @extend %body1_style;
    background-color: $grey_bg;
    height: calc(100vh - 56px);
    width: 100%;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .image {
        max-width: 100%;
        width: 500px;
        margin-top: auto;
        @include media($isPhone) {
            width: 300px;
        }
    }

    .text {
        margin-top: 56px;
        text-align: center;
        max-width: 640px;

        .title {
            @extend %headline5_style;
            margin-top: 0;
            font-weight: 700;
            @include media($isPhone) {
                font-size: 1rem;
            }
        }

        .subtitle {
            @extend %subtitle1_style;
            text-align: center;
            margin: 12px 0 0 0;
            @include media($isPhone) {
                font-size: 0.875rem;
            }

            ::v-deep a {
                text-decoration: none;
                color: $blue;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .footer {
        margin-top: auto;
        margin-bottom: 40px;

        .footer_text {
            @extend %caption_style;
            color: $grey_bombay;
            margin: 4px;
            text-align: center;
        }
    }
}
</style>
